import React, { useRef, useMemo, useState, useEffect } from 'react'

import Popup from 'reactjs-popup'

import Input from '../input'

import Button from '../button'

import styles from './style.module.scss'

import { TiDelete } from 'react-icons/ti'

import { OFFER_STATUS_ACCEPTED, OFFER_STATUS_DECLINED } from '../../constants'

const ChangeOfferStatus = ({ close, status, onSend }) => {
    const refModal = useRef({})
    const messageRef = useRef({})

    const statusVerb = useMemo(() => {
        switch (status) {
            case OFFER_STATUS_ACCEPTED: {
                return 'accept'
            }
            case OFFER_STATUS_DECLINED: {
                return 'decline'
            }
        }
        return ''
    }, [status])

    useEffect(() => {
        messageRef.current = refModal.current.querySelector('textarea')
    }, [refModal.current])

    return (
        <div className={styles.modal} ref={refModal}>
            <TiDelete className={styles.close} onClick={close} />
            <h2>{statusVerb} an offer</h2>
            <p>
                Are you sure you want to {statusVerb} the offer? The customer
                will be notified.
            </p>
            <Input
                type="textarea"
                label="Message"
                placeholder="Do you want to tell the customer anything? (optional)"
            />
            <div className={styles.actions}>
                <Button
                    className={styles.btn}
                    onClick={() => {
                        onSend({
                            status,
                            message: messageRef.current.value
                        })
                        close()
                    }}
                >
                    Send
                </Button>
            </div>
        </div>
    )
}

export default ({ children, status, disabled, onSend }) => {
    return (
        <Popup
            disabled={disabled}
            trigger={children}
            modal
            contentStyle={{ border: 0, width: 'initial' }}
        >
            {close => (
                <ChangeOfferStatus
                    status={status}
                    onSend={onSend}
                    close={close}
                />
            )}
        </Popup>
    )
}
