import React, { useMemo, useEffect, useCallback } from 'react'

import styles from './style.module.scss'

import { useOwnerOffers, useChangeOfferStatus } from '../hooks'

import { get, isEmpty } from 'lodash-es'

import Loader from '../loader'

import cn from 'classnames'

import Button from '../button'

import { DashboardBreadcrumb } from '../breadcrumb'

import format from '../format'

import { OFFER_STATUS_DECLINED, OFFER_STATUS_ACCEPTED } from '../../constants'

import ChangeOfferStatusPopup from '../change-offer-status-popup'

const OfferDetails = ({ offer }) => {
    const rows = useMemo(() => {
        if (!offer || isEmpty(offer)) return []

        const excludes = ['id', 'title', 'property_slug', 'owner']

        const keys = Object.keys(offer).filter(
            key => excludes.indexOf(key) === -1
        )

        return keys.map(key => ({
            key: format({ type: 'snakeToTitleCase', value: key }),
            value: offer[key]
        }))
    }, [offer])

    return (
        <table className={styles.offerDetails}>
            <tbody>
                {rows.map(row => (
                    <tr key={row.key}>
                        <td>{row.key}</td>
                        <td>{row.value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default () => {
    const {
        response,
        loading: loadingOffers,
        submit: loadOffers
    } = useOwnerOffers()

    const {
        statusCode: changeStatusCode,
        loading: changingStatus,
        submit: changeOfferStatus
    } = useChangeOfferStatus()

    const offers = useMemo(() => get(response, 'data') || [], [response])

    const loading = useMemo(() => loadingOffers || changingStatus, [
        loadingOffers,
        changingStatus
    ])

    useEffect(() => {
        if (changeStatusCode === 200) loadOffers()
    }, [changeStatusCode])

    return (
        <>
            {loading && <Loader />}
            <div className={styles.wrapper}>
                <DashboardBreadcrumb
                    breadcrumb={[
                        {
                            title: 'Dashboard',
                            link: '/owner-dashboard'
                        },
                        {
                            title: 'Offers'
                        }
                    ]}
                />
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1 className={styles.pageTitle}>Offers</h1>
                    </div>
                    {!offers.length && (
                        <div className={styles.noPropertiesCouldBeFound}>
                            <span>
                                No offers received, once you have a new offer we
                                will notify you.
                            </span>
                        </div>
                    )}
                    <div
                        className={cn('row', styles.offersRow, {
                            [styles.changing]: changingStatus
                        })}
                    >
                        {offers.map &&
                            offers.map(offer => (
                                <div
                                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4"
                                    key={offer.id}
                                >
                                    <div className={styles.offer}>
                                        <div className={styles.offerBody}>
                                            <div className={styles.offerTitle}>
                                                {offer.property_name}
                                            </div>
                                            <OfferDetails offer={offer} />
                                        </div>

                                        <div className={styles.offerFooter}>
                                            <ChangeOfferStatusPopup
                                                disabled={offer.status}
                                                status={OFFER_STATUS_DECLINED}
                                                onSend={({ message, status }) =>
                                                    changeOfferStatus({
                                                        message,
                                                        status,
                                                        id: offer.id
                                                    })
                                                }
                                            >
                                                <div className={styles.flex}>
                                                    <Button
                                                        disabled={offer.status}
                                                        className={
                                                            styles.decline
                                                        }
                                                    >
                                                        Decline
                                                    </Button>
                                                </div>
                                            </ChangeOfferStatusPopup>
                                            <ChangeOfferStatusPopup
                                                disabled={offer.status}
                                                status={OFFER_STATUS_ACCEPTED}
                                                onSend={({ message, status }) =>
                                                    changeOfferStatus({
                                                        message,
                                                        status,
                                                        id: offer.id
                                                    })
                                                }
                                            >
                                                <div className={styles.flex}>
                                                    <Button
                                                        disabled={offer.status}
                                                        className={
                                                            styles.accept
                                                        }
                                                    >
                                                        Accept
                                                    </Button>
                                                </div>
                                            </ChangeOfferStatusPopup>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}
